import { SignedOut, SignIn } from "@clerk/remix"
import { getAuth } from "@clerk/remix/ssr.server"
import { Box, Group, Image, Stack } from "@mantine/core"
import { LoaderFunctionArgs, redirect } from "@remix-run/server-runtime"

import Logo from "@kiosk/front/components/Logo"

import background from "./auth-background.png"

export async function loader(args: LoaderFunctionArgs) {
  const { userId } = await getAuth(args)

  // If the user is already logged in, redirect them to the dashboard.
  if (userId) {
    return redirect("/dashboard")
  }

  return null
}

export default function WelcomePage() {
  return (
    <SignedOut>
      <Stack align="flex-start" h="100vh">
        <Group gap={0} h="100%" p={24} w="100vw">
          <Stack align="flex-start" h="100%" justify="center" w="50%">
            <Box h={28}>
              <Logo />
            </Box>

            <Stack align="center" h="100%" justify="center" w="100%">
              <SignIn
                // Hide the sign-up button on login form
                afterSignOutUrl="/sign-in"
                appearance={{
                  elements: { footerAction: { display: "none" } },
                }}
                fallbackRedirectUrl="/dashboard"
                forceRedirectUrl="/dashboard"
              />
            </Stack>
          </Stack>

          <Stack h="100%" w="50%">
            <Image
              alt="auth image"
              fit="cover"
              flex={1}
              h="100%"
              radius="md"
              src={background}
              w="100%"
            />
          </Stack>
        </Group>
      </Stack>
    </SignedOut>
  )
}
